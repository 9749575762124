import styled from "styled-components";
import { H2, P } from "@/lib/styles";
import { forwardRef } from "react";
import CallToAction from "@/components/Button";
import { SVGArrowRight } from "@/lib/svg";

export const Container = styled.div``;

export const Heading = styled(H2)`
  margin-bottom: 0;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 50%;
  }
`;

export const Excerpt = styled(P)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0.5rem 0;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 50%;
  }
`;

const _Button = forwardRef<
  HTMLAnchorElement,
  { children: string; href?: string; target?: string; }
>((props, ref) => (
  <CallToAction
    ref={ref}
    as="a"
    variant="primaryBoxed"
    icon={<SVGArrowRight />}
    {...props}
  />
));

_Button.displayName = "_Button";

export const Button = styled(_Button)`
  transform: translateY(20px);

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  gap: 1.5rem;
`;
