/**
 * @see https://www.figma.com/file/16YSFRr06xdFYJFqNgvv41/Bormioli-Product-Design?node-id=124%3A64512
 */

import React from "react";
import {
  ButtonsContainer,
  Container,
  Excerpt,
  Heading,
} from "@/components/Marketing/PageBuilder/Paragraph/styles";
import InternalLink from "@/components/Marketing/PageBuilder/Paragraph/InternalLink";
import ExternalLink from "@/components/Marketing/PageBuilder/Paragraph/ExternalLink";

type Props = {
  heading?: string;
  excerpt?: string;
  buttons?: {
    _type: "internalLInk" | "externalLink";
    _key: string;
    title?: string;
    page?: string;
    url?: string;
    blank?: boolean;
  }[];
};

const Paragraph: React.FC<Props> = (props) => {
  const { heading, excerpt, buttons } = props;
  return (
    <Container>
      {heading && <Heading>{heading}</Heading>}
      {excerpt && <Excerpt>{excerpt}</Excerpt>}
      {buttons && buttons.length > 0 && (
        <ButtonsContainer>
          {buttons?.map((button: any) => {
            if (!button?._key) return null;
            switch (button._type) {
              case "internalLink":
                return <InternalLink key={button._key} {...button} />;
              case "externalLink":
                return <ExternalLink key={button._key} {...button} />;
              default:
                return null;
            }
          })}
        </ButtonsContainer>
      )}
    </Container>
  );
};

export default Paragraph;
