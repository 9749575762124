// import { resolveInternalLink } from "@/lib/helpers";
// import Link from "next/link";
// import React, { useMemo } from "react";
// import { Button } from "./styles";

export type InternalLinkProps = {
  title?: any;
  page?: Record<string, any>;
};

const InternalLink: React.FC<InternalLinkProps> = (props) => {
  return null;
  // const { title, page } = props;

  // const href = useMemo(() => {
  //   if (page?._type) {
  //     return resolveInternalLink(page?._type, page?.slug);
  //   }
  // }, [page?._type, page?.slug]);

  // if (typeof title !== "string") return null;
  // if (!href) return null;

  // return (
  //   <Link href={href} passHref>
  //     <Button>{title}</Button>
  //   </Link>
  // );
};

export default InternalLink;
